export default defineNuxtRouteMiddleware((to, from) => {
  if (to.params.category) {
    const isValid =
      to.params.category &&
      ['konsola', 'laptop', 'smartfon', 'tablet'].includes(to.params.category as string);

    if (!isValid) {
      return navigateTo('/not-found');
    }
  }
});
